import { useEffect, useRef, useState } from 'react';
import { EventsType, useIdleTimer } from 'react-idle-timer';
import { FormattedRelativeTime } from 'react-intl';
import { useMatch } from 'react-router';
import { addSeconds, differenceInSeconds } from 'date-fns';
import ButtonSolidRef from './ButtonSolid';
import Modal from './Modal';

const ACTIVE_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];

export default function KeepAlive({ onTimeout }: { onTimeout?: () => void }) {
  const [stopViewingDate, setStopViewingDate] = useState<Date | undefined>();

  const shouldKeepAlive = !!useMatch('/view/:publicId/camera/*');

  const keepAliveIntervalMilliseconds = 60 * 1000;

  const responseTimeSeconds = 120;

  const appearIdleTimer = useIdleTimer({
    events: ACTIVE_EVENTS,
    timeout: keepAliveIntervalMilliseconds,
    startOnMount: false,
    stopOnIdle: true,
    startManually: true,
    crossTab: true,
    onActive: () => {
      if (!shouldKeepAlive) {
        return;
      }

      setStopViewingDate(undefined);
      appearIdleTimer.reset();
      appearIdleTimer.start();
    },
    onIdle: () => {
      if (!shouldKeepAlive) {
        return;
      }

      const stopViewingDate = addSeconds(new Date(), responseTimeSeconds);
      setStopViewingDate(stopViewingDate);
    },
  });

  useEffect(() => {
    if (shouldKeepAlive && !stopViewingDate) {
      appearIdleTimer.start();
    }
    if (!shouldKeepAlive || !!stopViewingDate) {
      appearIdleTimer.pause();
    }
  }, [appearIdleTimer, stopViewingDate, shouldKeepAlive]);

  useEffect(() => {
    if (!stopViewingDate || !shouldKeepAlive) {
      return;
    }
    const remaining = stopViewingDate.getTime() - new Date().getTime();

    const handle = setTimeout(() => {
      onTimeout?.();
      setStopViewingDate(undefined);
    }, remaining);
    return () => clearTimeout(handle);
  }, [stopViewingDate, shouldKeepAlive, onTimeout]);

  const stayLoggedInButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      show={!!stopViewingDate && shouldKeepAlive}
      initialFocus={stayLoggedInButtonRef}
      onHide={() => setStopViewingDate(undefined)}>
      <Modal.Header>
        <h1>Are you still viewing?</h1>
      </Modal.Header>
      <Modal.Body>
        <>
          Please click 'Keep Viewing' to continue watching your current stream.
          <br />
          Your stream will automatically close{' '}
          {stopViewingDate ? (
            <FormattedRelativeTime
              value={differenceInSeconds(stopViewingDate, new Date())}
              updateIntervalInSeconds={1}
            />
          ) : (
            'soon.'
          )}
        </>
      </Modal.Body>

      <Modal.Footer>
        <ButtonSolidRef
          ref={stayLoggedInButtonRef}
          color="indigo"
          onClick={() => {
            setStopViewingDate(undefined);
          }}>
          Keep Viewing
        </ButtonSolidRef>
      </Modal.Footer>
    </Modal>
  );
}
