import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import genericLogo from 'shared/img/generic-logo.png';
import { ButtonSolid, KeepAlive } from './';
import Modal from './Modal';

export default function IdleTimeout() {
  const [showIdleTimeoutModal, setShowIdleTimeoutModal] = useState(false);

  const { publicId } = useParams<{ publicId?: string }>();
  const navigate = useNavigate();

  const handleTimeout = useCallback(() => {
    navigate(`/view/${publicId}`);
    setShowIdleTimeoutModal(true);
  }, [navigate, publicId]);

  return (
    <>
      <KeepAlive onTimeout={handleTimeout} />
      <Modal
        show={showIdleTimeoutModal}
        onHide={() => setShowIdleTimeoutModal(false)}>
        <Modal.Header>
          <img className="max-w-xl" src={genericLogo} alt="Logo" />
        </Modal.Header>
        <Modal.Body>
          <p className="text-xl font-bold text-center">Thank You For Viewing</p>
          <ButtonSolid
            className="mt-4"
            color="indigo"
            onClick={() => setShowIdleTimeoutModal(false)}>
            Continue Viewing
          </ButtonSolid>
        </Modal.Body>
      </Modal>
    </>
  );
}
