import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { Dialog } from '@headlessui/react';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import { useModalContext } from './ModalContext';
import Provider from './Provider';

export interface ModalProps {
  className?: string;
  panelClassName?: string;
  show: boolean;
  onHide: () => void;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  children?: ReactNode;
}
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Header = Header;
/** Provides all instances of Modal with a single overlay and ensure only one is visible at a time with a simple stack */
Modal.Provider = Provider;

/** Standard modal with blurring overlay. Ensure that a Modal.Provider is set high in the dom (one per app recommended) */
export default function Modal({
  show,
  onHide,
  initialFocus,
  className,
  panelClassName = 'bg-white rounded-md shadow-lg',
  children,
}: ModalProps) {
  const [instanceId] = useState(uniqueId('modal'));

  const [showBody, setShowBody] = useState(false);

  const { openInstanceName, addVisible, removeVisible } = useModalContext();

  useEffect(() => {
    if (!show) {
      return;
    }

    addVisible(instanceId);
    return () => {
      removeVisible(instanceId);
    };
  }, [addVisible, show, removeVisible, instanceId]);

  const isActiveInstance = openInstanceName === instanceId;
  const fallbackFocus = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // slight delay to allow opacity to be set via transition.
    // this is especially nice so a fading modal body can disappear before the next modal in the stack fades in
    const timeout = setTimeout(
      () => setShowBody(isActiveInstance),
      isActiveInstance ? 150 : 1
    );
    return () => clearTimeout(timeout);
  }, [isActiveInstance]);

  return (
    <Dialog
      className={classNames(
        className,
        'z-20 fixed top-0 left-0 right-0 flex flex-col h-screen overflow-hidden',
        {
          'hidden pointer-events-none': !isActiveInstance,
        }
      )}
      initialFocus={isActiveInstance ? initialFocus : fallbackFocus}
      open={show}
      onClose={handleHide}>
      {/* because there is a focus trap on dialog we cannot use Transition
      without some even worse hacks */}
      <div
        className={classNames(
          'flex flex-col flex-1 p-4 overflow-hidden transition-opacity',
          {
            'opacity-100': showBody,
            'opacity-0': !showBody,
          }
        )}>
        {/* TODO: hide this overlay element based on some sort of 'allow click off' prop (so some modals don't close when the user clicks off) */}
        {/* Note: this dialog.overlay provides the click off and calls 'onClose' when clicked. The actual blur / backdrop is in Modal.Provider */}
        <Dialog.Overlay className="fixed inset-0 z-0" />
        <div
          className={classNames(
            panelClassName,
            'z-10 flex flex-col max-w-full max-h-full my-auto overflow-hidden sm:m-auto'
          )}>
          {children}
        </div>
      </div>
      {!isActiveInstance && (
        // this allows the dialog to re-focus the default initial focus when we have several modals competing
        <button className="hidden" aria-hidden={true} ref={fallbackFocus}>
          dummy
        </button>
      )}
    </Dialog>
  );

  function handleHide() {
    if (!isActiveInstance) {
      return;
    }
    onHide();
  }
}
